<template>
  <div class="">
    <article class="card mb-4">
      <header class="card-header text-center font-proxima">
        <div class="card-meta fs--1">
          <strong>{{daysFrom('2020-05-05')}}</strong>
          в категории 
          <router-link to="/blog?category=management" 
            class="badge badge-secondary fs--0 text-uppercase">
            Управление
          </router-link>
        </div>
        <h1 class="card-title fs-2 mt-1 font-proxima">
          Что такое бизнес-модель? Доступно и просто
        </h1>
      </header>
      <img class="img-fluid" src="blogPost ? blogPost.imageUrl.i : ''" alt="" />
      <div class="card-body">
        <p>Понимание проблемы, которую Вы решаете для своих клиентов, несомненно, является самым большим вызовом, с которым Вы столкнетесь, когда начнете свой бизнес. Клиенты должны захотеть купить то, что Вы предлагаете, и ваш продукт должен решать вполне конкретную и реальную проблему.</p>
        <p>Но обеспечение того, чтобы ваш продукт соответствовал потребностям рынка, - это только один ключевой элемент успешного бизнеса.</p>
        <p>Другим дне менее важным элементом является определение того, каким способом Вы собираетесь зарабатывать деньги. Именно здесь в игру вступает выбранная Вами бизнес-модель.</p>

        <h5 class="mt-3 mb-2">
          Что такое бизнес-модель?
        </h5>
        <p>По своей сути, бизнес-модель - это описание того, как бизнес зарабатывает деньги. Она отвечает на вопрос, каким образом Вы доставляете ценность своим клиентам соответствующей цене.</p>
        <p>По словам Джоан Магретты, в книге "Почему бизнес-модели имеют значение", термин "бизнес-модель" стал широко использоваться с появлением персонального компьютера и электронных таблиц.</p>
        <p>Эти инструменты позволяют предпринимателям экспериментировать, тестировать и моделировать различные способы структурирования своих расходов и доходов. Электронные таблицы позволяют предпринимателям быстро вносить гипотетические изменения в свою бизнес-модель и сразу же видеть, как эти изменения повлияют на их финансовые результаты как в текущем моменте, так и в будущем.</p>
        <h5 class="mt-3">
          В общих чертах бизнес-модели можно разбить на три части:
        </h5>
        <ol>
          <li><strong>Все, что нужно для производства</strong>: дизайн, сырье, производство, рабочая сила и так далее.</li>
          <li><strong>Все, что нужно для продажи произведенной продукции</strong>: маркетинг, дистрибуция, предоставление услуг и постпродажное обслуживание.</li>
          <li><strong>Каким образом и за что платит клиент</strong>: ценовая стратегия, способы оплаты, сроки оплаты и так далее.</li>
        </ol>

        <p>Как Вы можете видеть, бизнес-модель - это просто последовательное исследование того, какие затраты и расходы Вы понесете и по какой цене Вы можете продать свой продукт или услугу.</p>
        <p>Чтобы быть успешной, бизнес-модель просто должна приносить денег больше, чем понесенные затраты на производство продукта или оказание услуги, что составляет вашу прибыль - все очень просто.</p>
        <p>В рамках новой бизнес-модели может быть усовершенствован любой из перечисленных трех компонентов. Возможно, Вам удасться снизить затраты на разработку и производство продукции. Или, возможно, Вы сможете найти более эффективные методы маркетинга и продаж. Или, может быть, вы можете придумать инновационный способ оплаты.</p>
        <p>Однако, имейте в виду, что Вам не обязательно придумывать новую бизнес-модель, чтобы иметь эффективную стратегию. Вместо этого Вы можете взять одну из существующих бизнес-модель и использовать ее для своих клиентов. Например, рестораны в основном работают по стандартной бизнес-модели, но фокусируют свою стратегию, ориентируясь на различные типы клиентов.</p>

        <h5 class="mt-3 mb-2">
          Различные виды бизнес-моделей
        </h5>
        <p>Вам не нужно изобретать совершенно новую бизнес-модель, чтобы начать свой бизнес. На самом деле подавляющее большинство компаний берут за основу одну из существующих бизнес-моделей и совершенствуют ее, для получения конкурентного преимущества. Ниже приведен список бизнес-моделей, которые можно использовать для запуска (или усовершенстования) собственного бизнеса.</p>
        
        <h6 class="fs-0 mt-3">
          1. Рекламная бизнес-модель
        </h6>
        <p>Рекламная бизнес-модель существует довольно давно и становится все более изощренной по мере того, как мир переходит от технологии выпуска печатной продукции к интернет технологиям. Основа этой модели крутится вокруг создания контента, который люди захотят прочитать или посмотреть, а затем показывать рекламу читателям или зрителям.</p>
        <p>В рекламной бизнес-модели необходимо удовлетворять две группы клиентов: ваших читателей или зрителей и потенциальных рекламодателей. Ваши читатели (зрители) могут как платить, так и нет, но ваши рекламодатели, безусловно, заплатят.</p>
        <p>Рекламная бизнес-модель иногда сочетается с моделью краудсорсинга, когда вы получаете свой контент бесплатно от пользователей, а не платите создателям контента за его разработку.</p>
        <p><strong>Примеры:</strong> РТ, Ведомости, YouTube</p>

        <h6 class="fs-0 mt-3">
          2.  Партнерская программа
        </h6>
        <p>Партнерская бизнес-модель тесно связана с рекламной бизнес-моделью, но имеет некоторые специфические отличия. Наиболее часто встречающаяся в интернете партнерская модель использует ссылки, встроенные в контент, а не визуальную рекламу, которую легко идентифицировать.</p>
        <p>Например, если Вы запускаете сайт книжного обозрения, Вы можете встроить в свои обзоры партнерские ссылки на Amazon или Озон, которые позволят людям купить просматриваемую книгу. Amazon же в свою очередь заплатит Вам небольшую комиссию за каждую успшную продаж, которой Вы способстовали.
        </p>
        <p><strong>Примеры:</strong> TheWireCutter.com, TopTenReviews.com</p>
        
        <h6 class="fs-0 mt-3">
          3. Посредничество
        </h6>
        <p>Брокерские (посреднические) компании сводят покупателей и продавцов, а также помогают облегчить проведение сделки. Они взимают плату за каждую транзакцию либо с покупателя, либо с продавца, а иногда и с обоих.</p>
        <p>Одним из наиболее распространенных примеров использования посредническлй бизнес-модели являются сделки с недвижимостью, но есть и много других типов брокерских компаний, таких как грузовые брокеры и брокеры, которые помогают строительным компаниям найти покупателей для грунта, который они достают при строительстве новых фундаментов.</p>
        <p><strong>Примеры:</strong> ReMax, RoadRunner Transportation Systems</p>

        <h6 class="fs-0 mt-3">
          4. Краудсорсинг
        </h6>
        <p>Если Вы можете собрать вместе большое количество людей, чтобы каждый мог внести свой вклад в развитие вашего сайта, то Вы используете краудсорсинг. Бизнес-модели краудсорсинга чаще всего сочетаются с рекламными моделями для получения дохода, но существует множество других итераций этой модели. Например, Threadless позволяет дизайнерам представлять проекты футболок и дает дизайнерам процент от продаж.</p>
        <p>Компании, которые пытаются решить сложные проблемы, часто публикуют свои задачи открыто для всех, кто заинтересуется их решением. Успешные решения получают вознаграждение. Ключ к успешному бизнесу краудсорсинга - это предоставление честных вознаграждений, чтобы привлечь "толпу", а также дать Вам возможность построить жизнеспособный бизнес.</p>
        <p><strong>Примеры:</strong> Threadless, YouTube, P&G Connect and Development, Cuusoo</p>

        <h6 class="fs-0 mt-3">
          5. Дезинтермедиация (отказ от посредничества)
        </h6>
        <p>Довольно таки новая бизнес-модель. Обычно, если производители хотят продавать свою продукцию в магазинах, то обычно им приходится работать через ряд посредников, чтобы "встать на полку" магазина.</p>
        <p>Отказ от посредничества - это когда Вы обходите стороной всех участников цепочки поставок и продаете напрямую потребителям, что позволяет потенциально снизить издержки для Ваших клиентов и иметь с ними прямые отношения.</p>
        <p><strong>Примеры:</strong> Casper, Dell</p>

        <h6 class="fs-0 mt-3">
          6. Частичное владение
        </h6>
        <p>Вместо того чтобы продавать весь продукт целиком, Вы можете продать только часть этого продукта с помощью бизнес-модели частичного владения.</p>
        <p>Одним из лучших примеров такой бизнес-модели является home-share, где группа людей владеет только частью загородного дома, что позволяет им использовать его в течение определенного количества недель в году.</p>
        <p><strong>Примеры:</strong> Disney Vacation Club, NetJets</p>

        <h6 class="fs-0 mt-3">
          7. Франшиза
        </h6>
        <p>Франчайзинг широко распространен в ресторанной индустрии, но Вы также легко найдете его практически во всех сферах услуг - от клининговых компаний до кадровых агентств.</p>
        <p>В рамках франчайзинговой бизнес-модели, Вы продаете механизм начала и ведения успешного бизнеса кому-то другому. Также обычно продается доступ к бренду и сервисам поддержки, которые помогают новому владельцу франшизы развивать локальную точку. По сути, фрашиза продает доступ к успешной бизнес-модели, которую Вы разработали.</p>
        <p><strong>Примеры:</strong> Ace Hardware, Mcdonald's, Allstate</p>

        <h6 class="fs-0 mt-3">
          8. Фримиум (fremium)
        </h6>
        <p>С помощью бизнес-модели freemium вы отдаете часть своего продукта или услуги бесплатно и взимаете плату за премиум-функции или услуги.</p>
        <p>Freemium - это не то же самое, что бесплатная пробная версия, когда клиенты получают доступ к продукту или услуге только в течение ограниченного периода времени. Вместо этого модели freemium позволяют неограниченно использовать базовые функции бесплатно и взимать плату только с тех клиентов, которые хотят получить доступ к более расширеным функциям.</p>
        <p>Примеры: MailChimp, Evernote, LinkedIn</p>

        <h6 class="fs-0 mt-3">
          9. Лизинг
        </h6>
        <p>Лизинг может показаться похожим на бизнес-модель частичного владения, но на самом деле они очень разные. При частичном владении Вы продаете вечный доступ к части чего-то. С другой стороны, лизинг - это все равно что аренда. В конце договора аренды клиент должен вернуть товар, который он арендовал у вас.</p>
        <p>Лизинг чаще всего используется для дорогих продуктов, где клиенты не могут позволить себе полную покупку, но вместо этого могут позволить себе арендовать продукт на некоторое время.</p>
        <p><strong>Примеры:</strong> "Аэрофлот-лизин", "ВТБ-Лизинг"</p>

        <h6 class="fs-0 mt-3">
          10. Бизнес-модель ценового преимущества
        </h6>
        <p>При использовании данной бизнес-модели компании снижают свои цены, предоставляя минимальный и только необходимый набор услуг. Некоторые из лучших примеров подобного типа бизнес-модели - это бюджетные авиакомпании (лоукосты) и продавцы мебели, такие как IKEA. В обоих этих случаях бизнес-модель ценового преимущества означает, что клиенты должны либо приобрести дополнительные услуги, либо сделать некоторые вещи самостоятельно, чтобы снизить затраты.</p>
        <p><strong>Примеры:</strong> IKEA, RyanAir</p>

        <h6 class="fs-0 mt-3">
          11. Торговая площадка (маркетплейс)
        </h6>
        <p>Торговые площадки позволяют продавцам предоставлять товары для продажи и обеспечивают клиентам простые инструменты для подключения.</p>
        <p>Бизнес-модель маркетплейса может генерировать доход из различных источников, включая комиссии от покупателей или продавцов за успешную сделку, дополнительные услуги по рекламе товаров продавца и страхования, для дополнительного спокойствия покупателей. Данная бизнес-модлье может быть использована как для продуктов, так и для услуг.</p>
        <p><strong>Примеры:</strong> eBay, Airbnb</p>

        <h6 class="fs-0 mt-3">
          12. Оплата за фактическое потребление
        </h6>
        <p>Вместо предварительной покупки определенного количества чего-то, например электроэнергии или минут сотового телефона, клиенты получают плату за фактическое использование в конце расчетного периода. Бизнес-модель фактического потребления наиболее распространена в коммунальных службах, но она таже была успешна применена к таким товарам, как чернила для принтеров.</p>
        <p><strong>Примеры:</strong> водные компании, HP Instant Ink</p>

        <h6 class="fs-0 mt-3">
          13. Лезвие бритвы
        </h6>
        <p>Бизнес-модель "лезвие бритвы" названа в честь продукта, который по существу изобрел эту модель: продайте долговечный продукт ниже себестоимости, чтобы увеличить объем продаж высокодоходного, одноразового компонента этого продукта.</p>
        <p>Вот почему компании по производству бритвенных лезвий практически отказываются от качественно бритвенного станка, предполагая, что Вы продолжите покупать большой объем сменных лезвий в долгосрочной перспективе. Цель состоит в том, чтобы привязать клиента к системе, гарантируя, что с течением времени будет много дополнительных, постоянных покупок.</p>
        <p><strong>Примеры:</strong> Gillette, струйные принтеры, Xbox, Amazon Kindle</p>

        <h6 class="fs-0 mt-3">
          14. Обратный аукцион
        </h6>
        <p>Бизнес-модель обратного аукциона переворачивает аукционы с ног на голову и заставляет продавцов предлагать покупателям самые низкие цены. Затем покупатели выбирают самую низкую цену, предоставленную им.</p>
        <p>Вы можете столнуться с реверсивными (обратные) аукционами в действии, когда подрядчики делают ставку на выполнение работ по строительному проекту. Также вы с ними сталкиваетесь при покупке в ипотеку или получении другого вида кредита.</p>
        <p><strong>Примеры:</strong> Priceline.com, LendingTree</p>
        
        <h6 class="fs-0 mt-3">
          15. Подписка
        </h6>
        <p>Бизнес-модели подписок становятся все более и более распространенными. В этой бизнес-модели потребители получают плату за подписку, чтобы получить доступ к услуге на определенное время.</p>
        <p>В то время как подписка на журналы и газеты существовала в течение длительного времени, эта модель теперь распространилась и на программное обеспечение, а также онлайн-сервисы и даже появилась в сфере услуг.</p>
        <p><strong>Примеры:</strong> Netflix, Salesforce, Comcast</p>

        <p>Здесь приведен далеко не исчерпывающий список всех существующих бизнес—моделей, но, надеюсь, он заставит вас задуматься о возможных способах структурирования своего бизнеса.</p>
        <p>Главное, что нужно помнить, - это то, что нет необходимости изобретать новую бизнес-модель при запуске нового бизнеса. Использование существующих моделей может помочь вам добиться успеха, поскольку уже есть явные доказательства, что она работает. И Вам останется всего лишь вносить небольшие нововедения, в рамках существующей бизнес-модели, чтобы Ваш бизнес развивался успешно.</p>
        <p>Однако, создание новая бизнес-модели может быть намного прибыльной затеей, но этот вариант также несет с собой и более высокие риски. Вы не можете знать наверняка, примут ли клиенты эту модель или нет.</p>

      </div>
      <div class="card-footer bg-light pt-0">
        <div class="row no-gutters font-weight-semi-bold text-center py-2 fs--1">
          <div class="col-auto mr-3">
            <img src="@/assets/img/illustrations/like-inactive.png" width="20" alt="">
            <span class="ml-2">
              {{blogPost ? blogPost.likes : 0}}
            </span>
          </div>
          <div class="col-auto mr-3">
            <img src="@/assets/img/illustrations/comment-inactive.png" width="20" alt="">
            <span class="ml-2">0</span>
          </div>
          <div class="col-auto d-flex align-items-center">
            <a class="rounded text-700 d-flex align-items-center" href="#!">
              <img src="@/assets/img/illustrations/share-inactive.png" width="20" alt="">
              <span class="ml-1">Поделиться</span>
            </a>
          </div>
        </div>
        <form class="d-flex align-items-center border-top border-200 pt-3">
          <div v-if="false" 
            class="avatar avatar-xl">
            <img class="rounded-soft"  alt="">
          </div>
          <input class="form-control rounded-capsule ml-2 fs--1" 
            disabled
            type="text"      
            placeholder="Оставьте комментарий...">
            <div class="btn btn-secondary btn-sm ml-3 disabled">Отправить</div>
        </form>
      </div>
    </article>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  mounted() {
    this.loadBlogPosts();
  },

  computed: {
    blogPost() {
      return this.$store.getters.getCurrentBlogPost;
    }
  },

  methods: {
    daysFrom(date) {
      return moment().to(date);
    },

    async loadBlogPosts() {
      const postId = this.$route.query.id;
      await this.$store.dispatch('fetchBlogPostsList');
      this.$store.commit('setCurrentBlogPost', postId)
    }
  }

}
</script>

<style>

</style>